import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import styles from './404.module.scss'

const NotFound = () => {
  return (
    <Layout page='404' bgColor='inherit'>
      <div className={styles.notFound__container}>
        <Link to='/'>
          <h1>Sorry, couldn't find that page.</h1>
        </Link>
      </div>
    </Layout>
  )
}

export default NotFound
